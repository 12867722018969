<div class="create-new-owner-modal">
    <div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <ng-template #addRecommendationModalContent let-modal>
                <!-- <div class="modal-content"> -->
                <form [formGroup]="inputForm" (ngSubmit)="saveRecommendation()">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            value="admin-home-owner" (click)="onCloseAllModels()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="add-footer-modal-heading">
                            <h4 *ngIf="recommendationId==0">New Home Recommendation</h4>
                            <h4 *ngIf="recommendationId!=0 && isDuplicate">Duplicate Recommendation</h4>
                            <h4 *ngIf="recommendationId!=0 && !isDuplicate">Edit Home Recommendation</h4>
                            <p>Fill the complete information about Home Recommendation</p>
                        </div>

                        <!-- <input type="hidden" formControlName="articleId"> -->
                        <div *ngIf="stepCode==1" class="create-new-owner-modal-form">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="enter-title-text">Recommendation Name <span>*</span></label>
                                        <input type="text" class="form-control" formControlName="recommendationTitle"
                                            placeholder="Add Recommendation Name" id="enter-title-text"
                                            (focusout)="commonOpsService.trimFormControlOnFocusOut($event, inputForm, 'recommendationTitle')">
                                        <div *ngIf="(formControls.recommendationTitle.invalid) && (submittedStep1 || formControls.recommendationTitle.dirty || formControls.recommendationTitle.touched)"
                                            class="text-danger">
                                            <div
                                                *ngIf="formControls.recommendationTitle.errors?.required || formControls.recommendationTitle.value==0">
                                                Recommendation name is required.
                                            </div>
                                            <div *ngIf="formControls.recommendationTitle.errors?.maxlength">
                                                Recommendation name can be max 150 characters long.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--  col-md-6   -->
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="short-description">Description <span>*</span></label>
                                        <textarea id="short-description" formControlName="descriptionText"
                                            class="form-control" rows="5" cols="50"
                                            placeholder="Write Description here."
                                            (focusout)="commonOpsService.trimFormControlOnFocusOut($event, inputForm, 'descriptionText')"></textarea>
                                        <div *ngIf="(formControls.descriptionText.invalid) && (submittedStep1 || formControls.descriptionText.dirty || formControls.descriptionText.touched)"
                                            class="text-danger">
                                            <div
                                                *ngIf="formControls.descriptionText.errors?.required || formControls.descriptionText.value==0">
                                                Description is required.
                                            </div>
                                            <div *ngIf="formControls.descriptionText.errors?.maxlength">
                                                Description can be max 500 characters long.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="addOptionalress">Optional Links</label>
                                        <div class="optional-links-box"
                                            *ngFor="let optionalLink of optionalLinks; let i=index">
                                            <input type="text" class="form-control" placeholder="Add Optional Links"
                                                [value]="optionalLink">

                                            <a href="javascript:;" (click)="onRemoveOptionalLink(i)"
                                                class="optional-links-add">-</a>
                                        </div>
                                        <div class="optional-links-box optional-display-off">
                                            <div class="optional-block-rec-admin">
                                                <input type="text" class="form-control" placeholder="Add Optional Links"
                                                    id="addOptionalress" formControlName="optionalLinkText">
                                                <a href="javascript:;"
                                                    [ngClass]="!(formControls.optionalLinkText.value==0) ? '' : 'disabled-add-more-button'"
                                                    (click)="onAddNewOptionalLink(formControls.optionalLinkText.value)"
                                                    class="optional-links-add">+</a>
                                            </div>
                                            <div *ngIf="(formControls.optionalLinkText.invalid) && (submittedStep1 || formControls.optionalLinkText.dirty || formControls.optionalLinkText.touched)"
                                                class="text-danger">

                                                <div *ngIf="formControls.optionalLinkText.errors?.pattern">
                                                    Please provide valid url.
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="selectedIconName">Icon Image <span>*</span></label>

                                        <div class="selects-icon-image">
                                            <dl class="dropdown">
                                                <dt>
                                                    <a href="javascript:;" (click)="toggleIconGallery()">
                                                        {{selectedIconName==''? 'Please select icon': selectedIconName}}
                                                        <span class="hida">

                                                            <input type="hidden" class="form-control facebook-input"
                                                                placeholder="Type Category Title" id="selectedIconName"
                                                                formControlName="iconFile">
                                                        </span>
                                                        <p class="multiSel"></p>
                                                    </a>
                                                </dt>

                                                <a href="javascript:;" class="drop-icon"><i
                                                        class="fas fa-angle-down"></i></a>

                                                <dd>
                                                    <div class="mutliSelect">
                                                        <ul id="iconGallery">
                                                            <li *ngFor="let icon of icons">
                                                                <a (click)="setCategoryIcon(icon.IconFile)"
                                                                    href="javascript:;"> <img
                                                                        src="assets/img/home-variable-icons/{{icon.IconFile}}"
                                                                        alt="Edit-Icon">
                                                                    <!-- <span>{{icon.iconName}}</span> -->
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </dd>

                                            </dl>
                                        </div>
                                        <div *ngIf="formControls.iconFile.invalid && (submittedStep1 || formControls.iconFile.dirty || formControls.iconFile.touched)"
                                            class="text-danger">
                                            <div *ngIf="formControls.iconFile.errors?.required">
                                                Icon is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="upload-image-box rec-home-pic upload-home-owner" *ngIf="profilePhoto">
                                        <img class="img-fluid" id="profilePhotoImgElement" [src]="profilePhoto"
                                            alt="profile-change-img">
                                    </div>
                                    <!-- <div class="text-danger img-size-error" *ngIf="!invalidSize">
                                        Maximum File Size Limit is 1MB
                                    </div> -->
                                    <ul class="upload-image-box-link">
                                        <li>

                                            <input type="file" accept="image/png, image/jpeg"
                                                id="profilePhotoBrowserInput"
                                                (change)="onProfilePhotoBrowserInputChange($event,'profilePhotoImgElement', '4.52')"
                                                style="display: none;">

                                            <a href="javascript:;"
                                                (click)="passClickToProfileFileInput('profilePhotoBrowserInput')"
                                                onclick="profilePhotoBrowserInput.value = null"
                                                class="btn-primary-small">Upload Home Image</a>
                                        </li>
                                        <li> <a href="javascript:;" *ngIf="profilePhoto" (click)="onRemoveImage()"
                                                class="btn-secondary-small">Remove</a></li>
                                    </ul>
                                </div>


                            </div>
                            <div class="row" *ngIf="!profilePhoto && submittedStep1">
                                <div class="col-md-12 text-danger">
                                    Image is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" *ngIf="stepCode==3" class="btn btn-primary">Save</button>
                    </div>
                </form>
            </ng-template>
        </div>
    </div>
</div>

<nav *ngIf="authService.isUserLoggedIn() && areREAMenuShown && !isNewHomeOwner; else noLoginNavBar"
    class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid nav-top-rea fixed-nav-bg">
        <div class="container">
            <a *ngIf="!isHOEnterpriseSponsor" class="navbar-brand" href="javascript:;" (click)="navigateToHome()"><img
                    src="assets/img/upkeepable-logo.svg" class="iZoticus-logo" alt="upkeepable-logo"></a>
                    <a *ngIf="isHOEnterpriseSponsor && logoImg" class="navbar-brand1" href="javascript:;" (click)="navigateToHome()"><img [ngClass]="{
                        'circle': shape === 'Circle',
                        'square': shape === 'Square',
                        'landscape-rectangle': shape === 'LandscapeRectangle',
                        'portrait-rectangle': shape === 'PortraitRectangle',
                        'rounded-corners': shape === 'RoundedCorners'
                      }"
                        [src]="logoImg" class="iZoticus-logo" alt="upkeepable-logo"></a>
            <!-- <div class="mobile-version" *ngIf="(userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER' || userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER') && authService?.getUserJwtDecodedInfo()?.IsAgree =='True'  "> -->
            <div class="mobile-version">
                <ul class="mainnav-ul">
                    <li ngbDropdown placement="bottom-end-video"
                        *ngIf="(userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER' || userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER') && (authService?.getUserJwtDecodedInfo()?.IsAgree =='True' || authService.isPrevUserId())"
                        class="video video-notification-drop">
                        <a id="dropdownvideo1" (click)="openVideoModal(viewVideoModalContent)"
                            class="video-notification"><img src="assets/img/question-mark-img.svg" alt="video-icon"></a>
                        <!-- <ul ngbDropdownMenu aria-labelledby="dropdownvideo1">
                            <li>
                                <div class="video-heading">
                                    <ul>
                                        <li> <a href="javascript:;" class="video-btn">Watch Video</a></li>
                                       </ul>       
                                </div>
                            </li>
                            
                        </ul> -->
                    </li>
                    <li ngbDropdown placement="bottom-end" class="hasDD bell-notification-drop">
                        <a id="dropdownBasic1" ngbDropdownToggle class="bell-notification"
                            (click)="onClickDropNotification()"><img src="assets/img/bell-icon.svg"
                                alt="bell-icon"><span
                                *ngIf="hasLatestNotification && !isShowNotificationClicked"></span></a>
                        <ul ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <li>
                                <div class="notification-heading">
                                    <h5>Notifications List</h5>
                                    <!-- <a href="#"><i class="fa fa-check"></i> Mark as read</a> -->
                                </div>
                            </li>
                            <li class="view-noti-icon-li" ngbDropdownItem *ngIf="notifications.length==0">
                                <div class="view-noti-icon">
                                    No latest notification yet..!
                                </div>
                            </li>
                            <li ngbDropdownItem *ngFor="let notification of notifications;">
                                <a href="javascript:;" (click)="viewAllNotifications()" class="notification-hover"></a>
                                <div class="notification-box">
                                    <!-- <span></span> -->
                                    <div class="notification-text-date">
                                        <p>{{notification.notificationTitle}}</p>
                                        <div class="date-noti">{{notification.createdOnFormatted}}</div>
                                    </div>
                                    <div class="notification-drop-icon">
                                        <img *ngIf="notification.notificationType==1"
                                            src="assets/img/password-drop-icon.svg" alt="password-drop-icon">
                                        <img *ngIf="notification.notificationType!=1"
                                            src="assets/img/profile-drop-icon.svg" alt="profile-drop-icon">
                                    </div>
                                </div>
                            </li>
                            <li ngbDropdownItem>
                                <div class="view-noti-icon">
                                    <a href="javascript:;" (click)="viewAllNotifications()">View all notifications</a>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li class="view-ho-pro-img">
                        <a id="dropdownBasic2" (click)="openProfileManagerPage()">
                            <!-- <p>{{authService.getUserJwtDecodedInfo().FullName}}</p> -->
                            <img *ngIf="userJwtDecodedInfo?.RoleName!='ADMIN_USER'" [src]="defaultProfilePhoto"
                                class="profile-img" alt="profile-img">
                        </a>
                    </li>
                    <!-- <li class="hasDD profile-drop d-inline-block" ngbDropdown placement="top-end">
                        <a>
                            <p>Brian Jackson</p> <img src="assets/img/rea-agent-img.jpg" class="profile-img" alt="profile-img">
                        </a>
                        <ul>
                            
                            <li><a href="#">My Settings</a></li>
                            <li><a href="#">Log Out</a></li>
                        </ul>
                    </li> -->


                </ul>
                <button class="navbar-toggler collapsed" (click)="mobileMenuButtonClick($event)" id="mobileMenuButton"
                    type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="icon-bar top-bar"></span>
                    <span class="icon-bar middle-bar"></span>
                    <span class="icon-bar bottom-bar"></span>
                </button>
            </div>

            <div *ngIf="authService.isUserLoggedIn() && authService.getUserJwtDecodedInfo().UserId"
                class="collapse navbar-collapse" [ngClass]="{'show': hasMobileMenuButtonHasClass()}"
                id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 mainnav-ul">
                    <li class="nav-item"
                        *ngIf="authService.isPrevUserId() && userJwtDecodedInfo?.RoleName!='ADMIN_USER'">
                        <a class="back-admin-link" href="javascript:;" (click)="directLogin(prevUserId)">Back to
                            Admin</a>
                    </li>
                    <li class="nav-item"
                        *ngIf="userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER' && (authService?.getUserJwtDecodedInfo()?.IsAgree =='True' || authService.isPrevUserId()) && !freeV">
                                <a class="nav-link active btn-success flip_modal_opener1" aria-current="page" (click)="onClicklinkDetails()"><i class="fa fa-share-alt"
                                aria-hidden="true" style="margin-right: 7px;"></i>share</a>
                    </li>
                    <li class="nav-item"
                        *ngIf="userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER' && (authService?.getUserJwtDecodedInfo()?.IsAgree =='True' || authService.isPrevUserId()) && freeV">
                                <a class="nav-link active btn-success" aria-current="page" (click)="onClickupgradeDetails(upgradeModalContent)">Upgrade</a>
                    </li>
                    <li class="nav-item"
                        *ngIf="userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER' && (authService?.getUserJwtDecodedInfo()?.IsAgree =='True' || authService.isPrevUserId())">
                        <a class="nav-link active" aria-current="page" (click)="onClickUpkeep('Dashboard')"
                            [ngClass]="{'selectd-menu-btn': menuCode=='Dashboard'}">Upkeep</a>
                    </li>
                    <li class="nav-item"
                        *ngIf="userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER' && (authService?.getUserJwtDecodedInfo()?.IsAgree =='True' || authService.isPrevUserId()) ">
                        <a class="nav-link" (click)="onClickHome('Details')"
                            [ngClass]="{'selectd-menu-btn': menuCode=='Details'}">Home</a>
                    </li>
                    <li ngbDropdown placement="bottom-end-video"
                        *ngIf="(userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER') && (authService?.getUserJwtDecodedInfo()?.IsAgree =='True' || authService.isPrevUserId())"
                        class="video video-notification-drop ho-video-link-new">
                        <a id="dropdownvideo1" (click)="openVideoModal(viewVideoHOModalContent)"
                            class="video-notification"><img src="assets/img/question-mark-img.svg" alt="video-icon"></a>
                    </li>
                    <!-- <li class="nav-item" *ngIf="userJwtDecodedInfo?.RoleName=='ADMIN_USER'">
                        <a (click)="openAddPriceModal(0, addPriceModalContent)" class="nav-link"
                            [ngClass]="{'selectd-menu-btn': menuCode=='Dashboard'}">Pricing</a>
                    </li>
                    <li class="nav-item" *ngIf="userJwtDecodedInfo?.RoleName=='ADMIN_USER'">
                        <a (click)="onClieckAdminDashboard('Dashboard')" class="nav-link"
                            [ngClass]="{'selectd-menu-btn': menuCode=='Dashboard'}">Dashboard</a>
                    </li>
                    <li class="nav-item" *ngIf="userJwtDecodedInfo?.RoleName=='ADMIN_USER'">
                        <a (click)="onClieckHomeVariables('home-variables-manager')" class="nav-link"
                            [ngClass]="{'selectd-menu-btn': menuCode=='home-variables-manager'}">Variables</a>
                    </li> -->

                    <li class="nav-mobile-setting-link"
                        *ngIf="((userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER' || userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER') && this.router.url !== '/Account/Settings' && !this.router.url.includes('Details')) && authService?.getUserJwtDecodedInfo()?.IsAgree =='True'  ">

                        <ul aria-labelledby="dropdownBasic2">
                            <li class="dummy-link" ngbDropdownItem (click)="openProfileManagerPage()">My Settings</li>
                            <li class="dummy-link" ngbDropdownItem (click)="authService.onLogoutClick()">Logout</li>
                        </ul>

                    </li>
                    <li class="nav-mobile-setting-link" *ngIf="userJwtDecodedInfo?.RoleName=='ADMIN_USER' ">

                        <ul aria-labelledby="dropdownBasic2">
                            <li class="dummy-link" ngbDropdownItem (click)="openProfileManagerPage()">My Settings</li>
                            <li class="dummy-link" ngbDropdownItem (click)="authService.onLogoutClick()">Logout</li>
                        </ul>

                    </li>
                    <li class="nav-mobile-setting-link"
                        *ngIf="authService.isPrevUserId() && userJwtDecodedInfo?.RoleName!='ADMIN_USER' ">

                        <ul aria-labelledby="dropdownBasic2">
                            <li class="dummy-link" ngbDropdownItem (click)="openProfileManagerPage()">My Settings</li>
                            <li class="dummy-link" ngbDropdownItem (click)="authService.onLogoutClick()">Logout</li>
                        </ul>

                    </li>
                    <li ngbDropdown placement="bottom-end-video"
                        *ngIf="(userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER') && (authService?.getUserJwtDecodedInfo()?.IsAgree =='True' || authService.isPrevUserId())"
                        class="video video-notification-drop">
                        <a id="dropdownvideo1" (click)="openVideoModal(viewVideoModalContent)"
                            class="video-notification"><img src="assets/img/question-mark-img.svg" alt="video-icon"></a>
                        <!-- <ul ngbDropdownMenu aria-labelledby="dropdownvideo1">
                            <li>
                                <div class="video-heading">
                                    <ul>
                                        <li> <a href="javascript:;" class="video-btn">wWatch Video</a></li>
                                       </ul>       
                                </div>
                            </li>
                            
                        </ul> -->
                    </li>

                    <li ngbDropdown placement="bottom-end" class="hasDD bell-notification-drop"
                        *ngIf="(userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER' || userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER') && (authService?.getUserJwtDecodedInfo()?.IsAgree =='True' || authService.isPrevUserId())">
                        <a id="dropdownBasic1" ngbDropdownToggle class="bell-notification"
                            (click)="onClickDropNotification()"><img src="assets/img/bell-icon.svg"
                                alt="bell-icon"><span
                                *ngIf="hasLatestNotification && !isShowNotificationClicked"></span></a>
                        <ul ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <li>
                                <div class="notification-heading">
                                    <h5>Notifications List</h5>
                                </div>
                            </li>
                            <li ngbDropdownItem *ngIf="notifications.length==0">
                                <div class="view-noti-icon">
                                    No latest notification yet..!
                                </div>
                            </li>
                            <li ngbDropdownItem *ngFor="let notification of notifications">
                                <a href="javascript:;" (click)="viewAllNotifications()" class="notification-hover"></a>
                                <div class="notification-box">
                                    <div class="notification-text-date">
                                        <p>{{notification.notificationTitle}}</p>
                                        <div class="date-noti">{{notification.createdOnFormatted}}</div>
                                    </div>
                                    <div class="notification-drop-icon">
                                        <img *ngIf="notification.notificationType==1"
                                            src="assets/img/password-drop-icon.svg" alt="password-drop-icon">
                                        <img *ngIf="notification.notificationType!=1"
                                            src="assets/img/profile-drop-icon.svg" alt="profile-drop-icon">
                                    </div>
                                </div>
                            </li>
                            <li ngbDropdownItem>
                                <div class="view-noti-icon">
                                    <a href="javascript:;" (click)="viewAllNotifications()">View all notifications</a>
                                </div>
                            </li>
                        </ul>
                    </li>

                    <li ngbDropdown placement="bottom-end" class="hasDD bell-notification-drop"
                        *ngIf="userJwtDecodedInfo?.RoleName=='ADMIN_USER'">
                        <a id="dropdownBasic1" ngbDropdownToggle class="bell-notification"
                            (click)="onClickDropNotification()"><img src="assets/img/bell-icon.svg"
                                alt="bell-icon"><span
                                *ngIf="hasLatestNotification && !isShowNotificationClicked"></span></a>
                        <ul ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <li>
                                <div class="notification-heading">
                                    <h5>Notifications List</h5>
                                </div>
                            </li>
                            <li ngbDropdownItem *ngIf="notifications.length==0">
                                <div class="view-noti-icon">
                                    No latest notification yet..!
                                </div>
                            </li>
                            <li ngbDropdownItem *ngFor="let notification of notifications">
                                <a href="javascript:;" (click)="viewAllNotifications()" class="notification-hover"></a>
                                <div class="notification-box">
                                    <div class="notification-text-date">
                                        <p>{{notification.notificationTitle}}</p>
                                        <div class="date-noti">{{notification.createdOnFormatted}}</div>
                                    </div>
                                    <div class="notification-drop-icon">
                                        <img *ngIf="notification.notificationType==1"
                                            src="assets/img/password-drop-icon.svg" alt="password-drop-icon">
                                        <img *ngIf="notification.notificationType!=1"
                                            src="assets/img/profile-drop-icon.svg" alt="profile-drop-icon">
                                    </div>
                                </div>
                            </li>
                            <li ngbDropdownItem>
                                <div class="view-noti-icon">
                                    <a href="javascript:;" (click)="viewAllNotifications()">View all notifications</a>
                                </div>
                            </li>
                        </ul>
                    </li>

                    <li ngbDropdown placement="bottom-end" class="hasDD profile-drop"
                        *ngIf="(userJwtDecodedInfo?.RoleName == 'HOME_OWNER_USER' || userJwtDecodedInfo?.RoleName == 'REAL_ESTATE_USER') && !authService.isPrevUserId() && authService?.getUserJwtDecodedInfo()?.IsAgree =='True'">
                        <a id="dropdownBasic2" ngbDropdownToggle>
                            <p>{{authService.getUserJwtDecodedInfo().FullName}}</p>
                            <img *ngIf="userJwtDecodedInfo?.RoleName!='ADMIN_USER'" [src]="defaultProfilePhoto"
                                class="profile-img" alt="profile-img">
                        </a>
                        <ul ngbDropdownMenu aria-labelledby="dropdownBasic2">
                            <!-- <li class="dummy-link" ngbDropdownItem *ngIf="userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER'" (click)="onClickHome()">My Profle</li> -->
                            <li class="dummy-link" ngbDropdownItem (click)="openProfileManagerPage()">My Settings</li>
                            <li *ngIf="!freeV && !paidV" class="dummy-link" ngbDropdownItem (click)="authService.onLogoutClick()">Logout</li>
                            <li *ngIf="freeV || paidV" class="dummy-link" ngbDropdownItem (click)="authService.onLogoutClick1()">Logout</li>
                        </ul>
                    </li>
                    <li ngbDropdown placement="bottom-end" class="hasDD profile-drop"
                        *ngIf="userJwtDecodedInfo?.RoleName == 'ADMIN_USER'">
                        <a id="dropdownBasic2" ngbDropdownToggle>
                            <p>{{authService.getUserJwtDecodedInfo().FullName}}</p> <img
                                *ngIf="userJwtDecodedInfo?.RoleName!='ADMIN_USER'" [src]="defaultProfilePhoto"
                                class="profile-img" alt="profile-img">
                        </a>
                        <ul ngbDropdownMenu aria-labelledby="dropdownBasic2">
                            <!-- <li class="dummy-link" ngbDropdownItem *ngIf="userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER'" (click)="onClickHome()">My Profle</li> -->
                            <li class="dummy-link" ngbDropdownItem (click)="openAddPriceModal(0, addPriceModalContent)">Pricing</li>
                            <li class="dummy-link" ngbDropdownItem (click)="onClieckAdminDashboard('Dashboard')">dashboard</li>
                            <li class="dummy-link" ngbDropdownItem (click)="onClieckHomeVariables('home-variables-manager')">variables</li>
                            <li class="dummy-link" ngbDropdownItem (click)="openProfileManagerPage()">My Settings</li>
                            <li class="dummy-link" ngbDropdownItem (click)="authService.onLogoutClick()">Logout</li>
                        </ul>
                    </li>
                    <li ngbDropdown placement="bottom-end" class="hasDD profile-drop"
                        *ngIf="authService.isPrevUserId() && userJwtDecodedInfo?.RoleName!='ADMIN_USER'">
                        <a id="dropdownBasic2" ngbDropdownToggle>
                            <p>{{authService.getUserJwtDecodedInfo().FullName}}</p> <img
                                *ngIf="userJwtDecodedInfo?.RoleName!='ADMIN_USER'" [src]="defaultProfilePhoto"
                                class="profile-img" alt="profile-img">
                        </a>
                        <ul ngbDropdownMenu aria-labelledby="dropdownBasic2">
                            <!-- <li class="dummy-link" ngbDropdownItem *ngIf="userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER'" (click)="onClickHome()">My Profle</li> -->
                            <li class="dummy-link" ngbDropdownItem (click)="openProfileManagerPage()">My Settings</li>
                            <li class="dummy-link" ngbDropdownItem (click)="authService.onLogoutClick()">Logout</li>
                        </ul>
                    </li>


                </ul>
            </div>
        </div>
    </div>
<!--Upgrade modal start-->
<div class="add-footer-link-modal">
    <div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <ng-template #upgradeModalContent let-modal>
                <!-- <div class="modal-content"> -->
                    <form #checkout="ngForm" (ngSubmit)="onSubmitStripe(checkout)">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                value="admin-home-owner" (click)="onCloseAllModels()"></button>
                        </div>
                        <div class="modal-body">
                            <div class="add-footer-modal-heading" *ngIf="!isPaymentMessage">
                                <h4 style="color: #0052A3">Upgrade and get the complete experience to protect and preserve your home.</h4>
                            </div>
                            <div class="add-footer-modal-heading" *ngIf="isPaymentMessage">
                                <h4 style="color: #0052A3">Your Account Has Been Upgraded!</h4>
                                <p>You now have full access to the home history log and all other premium features.</p>
                            </div>
    
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-md-7 col-9 ">
                                    <ul class="text-left my-3">
                                        <li style="color:#0061C1;"> <img alt="img-checked" src="assets/img/check-circle.png"> Your Home Profile</li>
                                        <li style="color:#0061C1;"> <img alt="img-checked" src="assets/img/check-circle.png"> Custom Maintenance Recommendations</li>
                                         <li style="color:#0061C1;"><img alt="img-checked" src="assets/img/check-circle.png"> Home Hints and Tips</li>
                                         <li style="color:#0061C1;"><img alt="img-checked" src="assets/img/check-circle.png"> Home History Log</li>
                                         <li style="color:#0061C1;"><img alt="img-checked" src="assets/img/check-circle.png"> Personalized Maintenance Logging</li>
                                     </ul>
                                </div>
                                <!--  col-md-6   -->
                            </div>
                            <h3 *ngIf="!isPaymentMessage" style="color: #0052A3; text-align: center;" class="my-3">$99/year</h3>
                            <div class="row"  *ngIf="!isPaymentMessage">
                                <div class=" form-group col-md-12">
                                    <label for="card-element">Payment Details<span>*</span></label>
                                    <div id="card-element" class="form-control" #cardInfo></div>
                                    <div id="card-errors" role="alert" *ngIf="isCardError" style="color: red;">{{ cardMessage }}</div>
                                </div>
                            </div>
                        
                        </div>
                        <div class="modal-footer"  *ngIf="!isPaymentMessage">
                            <button type="submit" class="btn btn-primary" style="width: 50%;">Upgrade</button>
                        </div>
                        <div class="modal-footer"  *ngIf="isPaymentMessage">
                            <button type="button" class="btn btn-primary" (click)="onClickUpkeepNospon('Dashboard')" style="width: 50%;">Go to dashboard</button>
                        </div>
                    </form>
            </ng-template>
            <!-- </div> -->
        </div>
    </div>
</div>
<!--Upgrade modal end-->
    <div class="create-new-entry-modal">
        <div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <ng-template #addPriceModalContent let-modal>
                    <!-- <div class="modal-content"> -->
                    <form [formGroup]="inputForm1" (ngSubmit)="saveEntry()">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                value="admin-home-owner" (click)="onCloseAllModels()"></button>
                        </div>
                        <div class="modal-body">
                            <div class="add-footer-modal-heading">
                                <p>Please fill out the Price</p>
                            </div>


                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="price">Enter a Title For Price<span>*</span></label>
                                        <input type="number" class="form-control" formControlName="Price_amount"
                                            placeholder="Add Price" id="price">
                                        <div *ngIf="(formControls1?.Price_amount?.invalid) && (submitted || formControls1?.Price_amount?.dirty || formControls1?.Price_amount?.touched)"
                                            class="text-danger">
                                            <div *ngIf="formControls1?.Price_amount?.errors?.required">
                                                Price is required.
                                            </div>
                                            <!-- <div *ngIf="formControls.title.errors?.maxlength">
                                                Title can be max 150 characters long.
                                            </div>
                                            <div *ngIf="formControls.title.errors?.pattern">
                                                Invalid Title.
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <!--  col-md-6   -->
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                    </form>
                </ng-template>
            </div>
        </div>
    </div>
<!--Flip modal start-->
<div class="flip_modal_upper_wrapper1">
    <div class="flip_modal_wrapper">
        <div class="flip_modal" [ngClass]="{'hide_modal1': isHide}">
            <div class="modal_1">
                <div class="modal-header modal-info-header-block">
                    <div class="modal-header-btn-height"> <button type="button" (click)="onModalCloseClick()"
                            class="btn-close" data-bs-dismiss="modal" id="btn1" aria-label="Close"></button></div>
                    <div class="home-img-box-modal" *ngIf="!isImage">
                        <img src="assets/img/Group1618.png" class="modal-home-img img-fluid" alt="modal-home-img">
                    </div>
                    <div *ngIf="isImage" class="home-img-box-modal">
                        <img src="assets/img/linkcopiedimg.png" class="modal-home-img img-fluid" alt="modal-home-img">
                    </div>
                    <div class="pt-5 text-center text-md-left">
                        <h4>Share Upkeepable With Friends and Family
                        </h4>
                    </div>
                    <div class="rea-profile-message admin-rea-msg">
                        <div class="row justify-content-center">
                            <div class="col-lg-10 col-md-10">
                                <div class="d-md-flex align-items-center text-center text-md-left">
                                    <img *ngIf="image == null" src="assets/img/default-profile-icon.png"
                                        class="rea-profile-img mr-lg-5 mr-md-3" alt="rea-profile-img">
                                        <img *ngIf="image != null && !paidV" [src]="image"
                                        class="rea-profile-img mr-lg-5 mr-md-3" alt="rea-profile-img">
                                    <p class="limitedTextSection" [class.showFullText]="show">
                                        I’d like to give you the opportunity to share Upkeepable with your friends
                                        and family, at my expense. Copy the link below and include in any messaging
                                        platform, or let people scan the QR code to get access.</p>

                                </div>
                            </div>
                        </div>
                        <div  class="rea-profile-message admin-rea-msg text-center" *ngIf="isMessage">
                            <p style="color: #66C275; font-size: 16px;">The shareable link has been copied to your clipboard.</p>
                        </div>
                       
                    </div>
                </div>
                <div class="modal-footer">
                    <button *ngIf="!isButtonText" (click)="onClickUpkeep1()" type="button" class="btn-secondary">copy link</button>
                    <button *ngIf="isButtonText" type="button" class=" btn_success">link copied</button>
                    <button (click)="openModal(qrCodeModalContent)" type="button" class="btn btn-primary flip_btn">qr
                        code</button>
                </div>
            </div>
            <div class="modal_2" >
                <div *ngIf="modalBool">
                <div class="modal-header">
                    <button type="button" class="btn-close" (click)="onModalCloseClick()" data-bs-dismiss="modal"
                        aria-label="Close" id="btn2"></button>
                </div>
                <div class="qr-modal-body">
                    <div class="qr-modal-heading">
                        <div class="row justify-content-center align-center">
                            <div class="col-md-10">
                                <div class="d-md-flex align-items-center text-center text-md-left">
                                    <img *ngIf="image == null" src="assets/img/default-profile-icon.png" class="rea-profile-img mr-lg-5 mr-md-3"
                                        alt="modal-home-img">
                                        <img *ngIf="image != null && !paidV" [src]="image" class="rea-profile-img mr-lg-5 mr-md-3"
                                        alt="modal-home-img">
                                    <p class="text-center text-md-left limitedTextSection">
                                        Use this QR code to share Upkeepable with
                                        others. Take a screenshot, or let them scan it with their mobile device to begin
                                        registering their home.
                                    </p> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="qr-modal-img">

                        <div *ngIf="!isHOEnterpriseSponsor" class="qr-modal-img-logo"><img id="qrLogo" src="assets/img/qr-modal-img-logo.png">
                        </div>
                        <div *ngIf="isHOEnterpriseSponsor" class="qr-modal-img-logo"><img id="qrLogo" [src]="image" style="width: 52px;height: 53px;">
                        </div>
                        <qrcode #event [id]="'canvasId'" [qrdata]="value" [allowEmptyString]="true"
                            [ariaLabel]="'QR Code image with the following content...'" [cssClass]="'center'"
                            [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [elementType]="'img'"
                            [errorCorrectionLevel]="'M'" [backgroundImage]="'assets/img/qr-logo-img-new.png'"
                            [imageHeight]="200" [imageWidth]="200" [margin]="2 " [scale]="1"
                            [title]="'A custom title attribute'" [width]="300"></qrcode>
                    </div>
                    <!-- value that you want to encode -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" id="flipnew" style="width:200px">go back</button>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
<!--Flip modal end-->


    <div class="add-footer-link-modal">
        <div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel">
            <div class="modal-dialog modal-lg modal-dialog-centered">

                <ng-template #viewVideoModalContent let-modal>


                    <div class="modal-header">
                        <button type="button" (click)="onVideoModalCancelClick()" class="btn-close"
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <br>
                    <div class="modal-body">
                        <div class="">

                            <div class="video-box">
                                <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/ONwQPHntU4I" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <!-- video link for Real Estate-->
                                <iframe
                                    *ngIf="(userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER') && (authService?.getUserJwtDecodedInfo()?.IsAgree =='True' || authService.isPrevUserId())"
                                    width="560" height="315" src="https://www.youtube.com/embed/VbRl-l4drwA"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>

                                <!-- video link for HO's Mobile version-->
                                <iframe
                                    *ngIf="(userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER') && (authService?.getUserJwtDecodedInfo()?.IsAgree =='True' || authService.isPrevUserId())"
                                    width="560" height="315" src="https://www.youtube.com/embed/5myZcjcz6sk"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                                <!-- <video width="100%" height="100%" controls
                                    poster="https://upkeepable.com/wp-content/uploads/2022/06/hero-image-new-o.png" loop
                                    autoplay controls="true">
                                    <source src="https://upkeepable.com/wp-content/uploads/2022/04/Intro.mp4"
                                        type="video/mp4">
                                </video> -->
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="help-request-modal">
                            <!-- <a href="javascript:;" (click)="addClass()" class="btn-primary-small">help request</a> -->
                            <a href="javascript:;" (click)="addClass()" class="btn-primary-small">get more help</a>
                        </div>
                    </div>
                </ng-template>
                <!-- </div> -->
            </div>
        </div>
    </div>



    <div class="add-footer-link-modal">
        <div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel">
            <div class="modal-dialog modal-lg modal-dialog-centered">


                <ng-template #viewVideoHOModalContent let-modal>


                    <div class="modal-header">
                        <button type="button" (click)="onVideoModalCancelClick()" class="btn-close"
                            data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <br>
                    <div class="modal-body">
                        <div class="">

                            <div class="video-box">


                                <div class="holder">
                                    <!-- <iframe width="" height="" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
                                    <iframe width="" height="" src="https://www.youtube.com/embed/ONwQPHntU4I"
                                        title="YouTube video player" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <div class="help-request-modal">
                            <!-- <a href="javascript:;" (click)="addClass()" class="btn-primary-small">help request</a> -->
                            <a href="javascript:;" (click)="addClass()" class="btn-primary-small">get more help</a>
                        </div>
                    </div>

                </ng-template>
                <!-- </div> -->
            </div>
        </div>
    </div>

    <div class="container rea-nav-link-row" *ngIf="userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER'">
        <div class="row rea-tab-msg">
            <div class="col-lg-12">
                <ul class="rea-nav-link"
                    *ngIf="userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER' && !this.router.url.includes('Details') && (authService?.getUserJwtDecodedInfo()?.IsAgree =='True' || authService.isPrevUserId())">
                    <li> <a (click)="onClieckViewAllClients('Dashboard')" class="nav-link"
                            [ngClass]="{'selectd-menu-btn': menuCode=='Dashboard', 'enterprise-link': isEnterpriseSponsor}">Clients List</a></li>
                    <li> <a (click)="onClieckViewAllMessages('Messages')" class="nav-link"
                            [ngClass]="{'selectd-menu-btn': menuCode=='Messages', 'enterprise-link': isEnterpriseSponsor}">Messages</a></li>
                            <li *ngIf="isEnterpriseSponsor"> <a (click)="onClieckViewAllArticles('Articles')" class="nav-link"
                                [ngClass]="{'selectd-menu-btn': menuCode=='Articles', 'enterprise-link': isEnterpriseSponsor}">Articles</a></li>
                        <li *ngIf="isEnterpriseSponsor"> <a (click)="onClieckViewAllFooterLinks('Links')" class="nav-link"
                        [ngClass]="{'selectd-menu-btn': menuCode=='Links' , 'enterprise-link': isEnterpriseSponsor}">Links</a></li>
                    <li> <a (click)="onClieckViewAllAnalytics('Analytics')" class="nav-link"
                        [ngClass]="{'selectd-menu-btn': menuCode=='Analytics', 'enterprise-link': isEnterpriseSponsor}">Analytics</a></li>
                </ul>
            </div>
        </div>
    </div>



</nav>

<div class="space-height-top"
    *ngIf="authService.isUserLoggedIn() && !isNewHomeOwner && userJwtDecodedInfo?.RoleName!='REAL_ESTATE_USER' && !hideheader">
</div>
<ng-template #noLoginNavBar>
    <header *ngIf="!isNewHomeOwner && !hideheader" class="login-page-header">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 align-center">
                    <div class="logo-box login-logo-align">
                        <a href="javascript:;" *ngIf="!authService.isUserLoggedIn() || !areREAMenuShown"><img
                                src="assets/img/upkeepable-white-logo.svg" class="iZoticus-logo"
                                alt="upkeepable-logo"></a>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div *ngIf="!isNewHomeOwner && !hideheader" class="space-height-top"></div>
</ng-template>


<app-http-loader></app-http-loader>
<app-toast-container aria-live="polite" aria-atomic="true"></app-toast-container>
<router-outlet></router-outlet>

<div *ngIf="!hideheader" class="space-height-bottom"></div>

<footer *ngIf="!hideFooter" [ngClass]="(authService.isUserLoggedIn() && areREAMenuShown)?'footer-home':'fixed-footer'"
    class="wow fadeInDown" data-wow-delay=".15s">
    <div class="container">
        <div class="row align-items-center justify-content-between flex-column flex-sm-row">
            <div class="col-lg-6 col-md-6 order-1 order-md-1 order-2">
                <div class="copy-right-text">
                    <div class="small m-0 text-white">Copyright © Upkeepable {{date| date:'yyyy'}}</div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 order-1">
                <div class="footer-link">
                    <a class="link-light small" href="javascript:;" *ngIf="!authService.isUserLoggedIn()"
                        (click)="goToLoginPage()">Home</a>
                    <a class="link-light small" href="javascript:;" (click)="openStaticPage('privacy')">Privacy</a>
                    <!-- <a class="link-light small" href="javascript:;" (click)="openStaticPage('terms')">Terms</a> -->
                    <a class="link-light small" href="javascript:;" (click)="openStaticPage('legal')">Legal</a>
                    <!-- <a class="link-light small" href="https://help.upkeepable.com/" target="_blank">Help</a>  -->
                    <a class="link-light small" href="javascript:;" (click)="addClass()">Help</a>

                    <!-- <a href="javascript:;" (click)="openAddRecommendationModal(0,false, addRecommendationModalContent)" class="link-light small">Help</a> -->
                </div>
            </div>
        </div>
    </div>
    
</footer>

<div class="modal" [ngClass]="{'show': isClassApplied}" id="contact_modal" tabindex="-1"
aria-labelledby="exampleModalLabel" aria-hidden="true">

<div class="modal-dialog modal-lg modal-dialog-centered">
    <!-- <div class="modal-content"> -->
    <div class="modal-content">
        <div class="modal-header">
            <button (click)="removeClass()" type="button" data-bs-dismiss="modal" aria-label="Close"
                class="btn-close"></button>
        </div>
        <div class="modal-body">
            <div class="login-heading mt-0 mb-5">
                <h4>Need Help? Ask Us.</h4>
                <h6>Submit this form to get support from our team</h6>
            </div>
            <form [formGroup]="inputForm" (ngSubmit)="onSubmit()">
                <div id="message" class="tabcontent" style="width:100%;">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="profile-information-box">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="firstName">First Name <span>*</span></label>
                                            <input type="text" class="form-control" formControlName="firstName"
                                                placeholder="First Name" id="first-name"
                                                (focusout)="commonOpsService.trimFormControlOnFocusOut($event, inputForm, 'firstName')">
                                            <div *ngIf="formControls.firstName.invalid && (submitted || formControls.firstName.dirty || formControls.firstName.touched)"
                                                class="text-danger">
                                                <div *ngIf="formControls.firstName.errors?.required">
                                                    First name is required.
                                                </div>
                                                <div *ngIf="formControls.firstName.errors?.pattern">
                                                    Invalid First name.
                                                </div>
                                                <div *ngIf="formControls.firstName.errors?.maxlength">
                                                    First name can be max 20 characters long.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="lastName">Last Name <span>*</span></label>
                                            <input type="text" formControlName="lastName" class="form-control"
                                                placeholder="Last Name" id="last-name"
                                                (focusout)="commonOpsService.trimFormControlOnFocusOut($event, inputForm, 'lastName')">
                                            <div *ngIf="formControls.lastName.invalid && (submitted || formControls.lastName.dirty || formControls.lastName.touched)"
                                                class="text-danger">
                                                <div *ngIf="formControls.lastName.errors?.required">
                                                    Last name is required.
                                                </div>
                                                <div *ngIf="formControls.lastName.errors?.pattern">
                                                    Invalid Last name.
                                                </div>
                                                <div *ngIf="formControls.lastName.errors?.maxlength">
                                                    Last name can be max 20 characters long.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="email">Email address <span>*</span></label>
                                            <input type="email" formControlName="email" class="form-control"
                                                placeholder="Email">
                                            <div *ngIf="(formControls.email.invalid) && (submitted || formControls.email.dirty || formControls.email.touched)"
                                                class="text-danger">
                                                <div *ngIf="formControls.email.errors?.required">
                                                    Email address is required.
                                                </div>
                                                <div *ngIf="formControls.email.errors?.pattern">
                                                    Invalid email address.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="message">Message<span>*</span></label>
                                            <textarea type="message" formControlName="message"
                                                class="form-control" placeholder="Message">
                                                </textarea>
                                            <div *ngIf="(formControls.message.invalid) && (submitted || formControls.message.dirty || formControls.message.touched)"
                                                class="text-danger">
                                                <div *ngIf="formControls.message.errors?.required">
                                                    Message is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="ho-save-info-box ho-save-info-box-new">
                                <button type="submit" style="margin-bottom: 62px;"
                                    class="btn btn-primary">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- </div> -->
</div>
</div>